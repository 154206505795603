import React, { useMemo, useState } from 'react';
import { ClickableField, DialogSection, Nullable } from '@linetweet/linetweet-ui';
import { CircularProgress, FormHelperText, Stack, Tooltip } from '@mui/material';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import { Alert } from '@mui/lab';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { Spin } from 'features/commons';
import styles from './SlotSelectSection.module.scss';
import { SlotButtonGroup } from '../subcomponents';
import { AppointmentFormSlotItem } from '../types';

type Props = {
  value: Nullable<AppointmentFormSlotItem>;
  onChange: (value: Nullable<AppointmentFormSlotItem>) => void;
  options: AppointmentFormSlotItem[];
  loading?: boolean;
  isWarning?: boolean;
  isDisabled?: boolean;
  before?: React.ReactNode;
  employeeId?: Nullable<string>;
  swapEnabled?: boolean;
  swapOptions?: AppointmentFormSlotItem[];
  onFetchSwapOptionsClick?: () => void;
  swapOptionsLoading?: boolean;
  swapOptionsError?: boolean;
};

type SwapSlotInputProps = {
  value: Nullable<AppointmentFormSlotItem>;
  onChange: (value: Nullable<AppointmentFormSlotItem>) => void;
  employeeId?: string;
  options?: AppointmentFormSlotItem[];
  onFetchOptionsClick?: () => void;
  optionsLoading?: boolean;
  optionsError?: boolean;
  isDisabled?: boolean;
};

function SwapSlotInput({
  value,
  onChange,
  employeeId,
  options,
  onFetchOptionsClick,
  optionsLoading,
  optionsError,
  isDisabled,
}: SwapSlotInputProps) {
  const intl = useIntl();

  return (
    <>
      {employeeId && (
        <>
          {((!options && !optionsError) || optionsLoading) && (
            <Stack direction="row" spacing={1} alignItems="center">
              { optionsLoading && (
                <CircularProgress size="16px" />
              ) }

              <div
                className={clsx(styles.swapTimesFetchButton, {
                  [styles.swapTimesFetchButtonDisabled]: optionsLoading || isDisabled,
                })}
              >
                <ClickableField
                  disabled={optionsLoading || isDisabled}
                  onClick={onFetchOptionsClick}
                  label={intl.formatMessage({ id: 'appointment.swapTimesFetch' })}
                />
              </div>

              <Tooltip
                slotProps={{
                  tooltip: {
                    className: styles.swapTooltip,
                  },
                }}
                title={(
                  <Alert
                    sx={{ whiteSpace: 'pre-wrap' }}
                    icon={false}
                    severity="error"
                    className={styles.swapAlert}
                  >
                    { intl.formatMessage({ id: 'appointment.swapTimesDescription' }) }
                  </Alert>
                )}
              >
                <InfoOutlinedIcon className={styles.swapInfoIcon} />
              </Tooltip>
            </Stack>
          )}

          {!!(!optionsLoading && (options || optionsError)) && (
            <DialogSection title={intl.formatMessage({ id: 'appointment.swapTimes' })}>
              {!!(options && options.length) && (
                <SlotButtonGroup
                  dataTestId="slot-selection-swap-times-list"
                  value={value}
                  onChange={onChange}
                  options={options}
                  isDisabled={isDisabled}
                />
              )}

              {!!((options && !options.length) || optionsError) && (
                <FormHelperText>
                  { intl.formatMessage({ id: 'appointment.swapTimesEmpty' }) }
                </FormHelperText>
              )}
            </DialogSection>
          )}
        </>
      )}

      {!employeeId && (
        <Alert severity="error" className={styles.swapAlert}>
          { intl.formatMessage({ id: 'appointment.swapTimesEmployeeNotSelected' }) }
        </Alert>
      )}
    </>
  );
}

SwapSlotInput.defaultProps = {
  employeeId: undefined,
  options: undefined,
  onFetchOptionsClick: undefined,
  optionsLoading: undefined,
  optionsError: undefined,
  isDisabled: undefined,
};

export function SlotSelectSection({
  before,
  value,
  isWarning,
  onChange,
  options: propsOptions,
  loading,
  isDisabled,
  employeeId,
  swapEnabled,
  swapOptions,
  onFetchSwapOptionsClick,
  swapOptionsLoading,
  swapOptionsError,
}: Props) {
  const intl = useIntl();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const plainSlotValue = useMemo(() => (value && !value.extra?.isSwap ? value : null), [value]);

  const swapSlotValue = useMemo(() => (value && value.extra?.isSwap ? value : null), [value]);

  const options = useMemo(() => {
    if (plainSlotValue?.value) {
      const filteredOptions = propsOptions.filter((option) => (
        option.value !== plainSlotValue?.value
      ));
      return [plainSlotValue, ...filteredOptions];
    }

    return propsOptions;
  }, [propsOptions, plainSlotValue]);

  const preferredOptions = useMemo(() => options.slice(0, 3), [options]);

  return (
    <Spin loading={loading}>
      <Stack
        direction="column"
        spacing={1.5}
        className={isWarning ? styles.warningContainer : undefined}
      >
        <DialogSection
          title={intl.formatMessage({ id: 'appointment.availableTimes' })}
        >
          <SlotButtonGroup
            value={plainSlotValue}
            isDisabled={!!isDisabled}
            onChange={onChange}
            options={isExpanded ? options : preferredOptions}
            before={before}
            dataTestId="slot-select-section"
          />
        </DialogSection>

        {isExpanded && swapEnabled && (
          <SwapSlotInput
            value={swapSlotValue}
            onChange={onChange}
            employeeId={employeeId || undefined}
            options={swapOptions}
            onFetchOptionsClick={onFetchSwapOptionsClick}
            optionsLoading={swapOptionsLoading}
            optionsError={swapOptionsError}
            isDisabled={isDisabled}
          />
        )}

        {!!(swapEnabled || options.length > 3) && (
          <ClickableField
            data-testid="expand-more"
            disabled={loading || !!isDisabled}
            onClick={() => setIsExpanded(!isExpanded)}
            label={intl.formatMessage({ id: isExpanded ? 'appointment.showPreferred' : 'appointment.showAll' })}
          />
        )}
      </Stack>
    </Spin>
  );
}

SlotSelectSection.defaultProps = {
  before: undefined,
  loading: false,
  isWarning: false,
  isDisabled: false,
  employeeId: undefined,
  swapEnabled: false,
  swapOptions: undefined,
  onFetchSwapOptionsClick: undefined,
  swapOptionsLoading: false,
  swapOptionsError: false,
};
