import React, { useMemo } from 'react';
import { alpha, Divider, Stack } from '@mui/material';
import clsx from 'clsx';
import { BreakComponentProps, CustomEventProps } from '../types';

import styles from './DayEvent.module.scss';
import { PinnedIcon } from '../../../assets/icons';
import { PauseIcon } from '../../../assets/DesignAssets/BTL/Icons';

export function Break60({
  title,
  timeRange,
  note,
  pinned,
  borderColor,
  backgroundColor,
  dividerColor,
  indicatorColor,
  serviceBackgroundColor,
  isProtected,
}: BreakComponentProps) {
  return (
    <div
      style={{ borderColor, backgroundColor }}
      className={clsx(styles.dayEventRoot, isProtected ? styles.protected : styles.dayEventBreak)}
    >
      <Stack direction="row" spacing={1.5} className={styles.dayEventContent}>
        <div className={clsx(styles.dayEventServicesItem)} style={{ backgroundColor: serviceBackgroundColor }}>
          <PauseIcon />
        </div>

        <Stack direction="column" overflow="hidden" spacing={1} flex={1} className={styles.dayEventBody}>
          <Stack direction="row" spacing={1}>
            <p
              className={clsx(
                styles.dayEventBodyText,
                styles.dayEventBodyTextBold,
                styles.dayEventBodyTextNoWrap,
                styles.dayEventBodyTextFullWidth,
              )}
            >
              {title}
            </p>

            {pinned && (
              <div className={styles.dayEventPinnedIconRoot}>
                <PinnedIcon />
              </div>
            )}

            {indicatorColor && <span className={styles.dayEventIndicator} style={{ backgroundColor: indicatorColor }} />}
          </Stack>

          <span className={clsx(styles.dayEventBodyText, styles.dayEventBodyTextSecondary, styles.dayEventTimeRange)}>{timeRange}</span>

          {note && (
            <>
              <Divider orientation="horizontal" flexItem style={{ borderColor: dividerColor }} />

              <span className={clsx(styles.dayEventBodyText, styles.dayEventBodyTextSecondary, styles.dayEventBodyTextNoWrap)}>{note}</span>
            </>
          )}
        </Stack>
      </Stack>
    </div>
  );
}

export function Break30({
  title,
  timeRange,
  pinned,
  borderColor,
  backgroundColor,
  indicatorColor,
  serviceBackgroundColor,
  note,
  isProtected,
}: BreakComponentProps) {
  return (
    <div
      style={{ borderColor, backgroundColor }}
      className={clsx(styles.dayEventRoot, isProtected ? styles.protected : styles.dayEventBreak)}
    >
      <Stack direction="row" spacing={1.5} className={styles.dayEventContent}>
        <div className={clsx(styles.dayEventServicesItem)} style={{ backgroundColor: serviceBackgroundColor }}>
          <PauseIcon />
        </div>

        <Stack direction="column" overflow="hidden" spacing={1} flex={1} className={styles.dayEventBody}>
          <Stack direction="row" spacing={1}>
            <p
              className={clsx(
                styles.dayEventBodyText,
                styles.dayEventBodyTextBold,
                styles.dayEventBodyTextNoWrap,
                styles.dayEventBodyTextFullWidth,
              )}
            >
              {title}
            </p>

            {pinned && (
              <div className={styles.dayEventPinnedIconRoot}>
                <PinnedIcon />
              </div>
            )}

            {indicatorColor && <span className={styles.dayEventIndicator} style={{ backgroundColor: indicatorColor }} />}
          </Stack>

          <span className={clsx(styles.dayEventBodyText, styles.dayEventBodyTextSecondary, styles.dayEventTimeRange)}>{timeRange}</span>

          {note && (
            <>
              <span className={clsx(styles.dayEventBodyText, styles.dayEventBodyTextSecondary, styles.dayEventBodyTextNoWrap)}>{note}</span>
            </>
          )}
        </Stack>
      </Stack>
    </div>
  );
}

export function Break15({
  timeRange,
  pinned,
  borderColor,
  backgroundColor,
  indicatorColor,
  serviceBackgroundColor,
  isProtected,
}: BreakComponentProps) {
  return (
    <div
      style={{ borderColor, backgroundColor }}
      className={clsx(styles.dayEventRoot, styles.dayEventRootDense, isProtected ? styles.protected : styles.dayEventBreak)}
    >
      <Stack direction="row" spacing={1.5} className={styles.dayEventContent}>
        <div
          className={clsx(styles.dayEventServicesItem, styles.dayEventServicesItemDense)}
          style={{ backgroundColor: serviceBackgroundColor }}
        >
          <PauseIcon />
        </div>

        <Stack direction="row" spacing={1.5} flex={1} alignItems="center" className={clsx([styles.dayEventBody, styles.dayEventBodyDense])}>
          <span
            className={clsx(
              styles.dayEventBodyText,
              styles.dayEventBodyTextSecondary,
              styles.dayEventBodyTextFullWidth,
              styles.dayEventTimeRange,
            )}
          >
            {timeRange}
          </span>

          <Stack direction="row" spacing={1}>
            {pinned && (
              <div className={styles.dayEventPinnedIconRoot}>
                <PinnedIcon />
              </div>
            )}

            {indicatorColor && <span className={styles.dayEventIndicator} style={{ backgroundColor: indicatorColor }} />}
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
}

export function DayEventBreak({ event, title }: CustomEventProps) {
  const DayEventComponent = useMemo(() => {
    const duration = event.duration || 10;

    if (duration >= 60) {
      return Break60;
    }

    if (duration >= 30) {
      return Break30;
    }

    return Break15;
  }, [event.duration]);

  const timeRange = useMemo(() => `${event.startFormatted} - ${event.endFormatted}`, [event]);

  const colorProps = useMemo(
    () => ({
      serviceBackgroundColor: alpha('#9D9D9D', 0.2),
      dividerColor: alpha('#9D9D9D', 0.2),
      indicatorColor: event.note && '#65FFBF',
    }),
    [event],
  );

  return (
    <DayEventComponent
      title={title}
      timeRange={timeRange}
      event={event}
      note={event.note}
      pinned={!!event.suggestedEmployee}
      isProtected={event.isProtected}
      {...colorProps}
    />
  );
}
