import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import { Stack } from '@mui/material';
import styles from './DayEvent.module.scss';
import { CustomEventProps, SlotComponentProps } from '../types';
import { ServiceIcon, setGlobalCSSVariable, removeGlobalCSSVariable } from '../../commons';
import { LinkedIcon } from 'assets/icons';

const HOVER_RELATED_COLORS = {
  borderColor: '#1e3549',
  backgroundColor: '#1e3549',
  textColor: '#FFFFFF',
};

function Slot15({
  timeRange,
  event,
  borderColor,
  backgroundColor,
  serviceBackgroundColor,
  textColor,
  onMouseEnter,
  onMouseLeave,
}: SlotComponentProps) {
  return (
    <div
      style={{ borderColor, backgroundColor, color: textColor }}
      className={clsx(styles.dayEventRoot, styles.dayEventSlot, styles.dayEventRootDense)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Stack direction="row" spacing={1.5} className={styles.dayEventContent}>
        <Stack direction="row" spacing={0.5}>
          {event.services.map((service) => (
            <div
              key={`${event.id}-${service.id}`}
              className={clsx([styles.dayEventServicesItem, styles.dayEventServicesItemDense])}
              style={{ backgroundColor: serviceBackgroundColor }}
            >
              <ServiceIcon icon={service.icon} />
            </div>
          ))}
          {event.relatedSlotId && <LinkedIcon className={styles.linkedIcon15} />}
        </Stack>

        <Stack direction="row" spacing={1.5} flex={1} alignItems="center" className={clsx([styles.dayEventBody, styles.dayEventBodyDense])}>
          <span className={clsx(styles.dayEventBodyText, styles.dayEventBodyTextSecondary, styles.dayEventTimeRange)}>{timeRange}</span>
        </Stack>
      </Stack>
    </div>
  );
}

function Slot30({
  title,
  timeRange,
  event,
  borderColor,
  backgroundColor,
  serviceBackgroundColor,
  textColor,
  onMouseEnter,
  onMouseLeave,
}: SlotComponentProps) {
  return (
    <div
      style={{ borderColor, backgroundColor, color: textColor }}
      className={clsx(styles.dayEventRoot, styles.dayEventRootCompact, styles.dayEventSlot)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Stack direction="row" spacing={1.5} className={styles.dayEventContent}>
        <Stack direction="row" spacing={0.5}>
          {event.services.map((service) => (
            <div
              key={`${event.id}-${service.id}`}
              className={clsx([styles.dayEventServicesItem])}
              style={{ backgroundColor: serviceBackgroundColor }}
            >
              <ServiceIcon icon={service.icon} />
            </div>
          ))}
          {event.relatedSlotId && <LinkedIcon className={styles.linkedIcon30} />}
        </Stack>

        <Stack direction="column" overflow="hidden" spacing={0.8} flex={1} className={styles.dayEventBody}>
          <span className={clsx(styles.dayEventBodyText, styles.dayEventBodyTextBold, styles.dayEventBodyTextNoWrap)}>{title}</span>

          <span className={clsx(styles.dayEventBodyText, styles.dayEventBodyTextSecondary, styles.dayEventTimeRange)}>{timeRange}</span>
        </Stack>
      </Stack>
    </div>
  );
}

export function DayEventSlot({ event }: CustomEventProps) {
  const intl = useIntl();

  const SlotComponent = useMemo(() => {
    const duration = event.duration || 10;

    if (duration >= 30) {
      return Slot30;
    }

    return Slot15;
  }, [event.duration]);

  const title = useMemo(() => event.services.map((service) => intl.formatMessage({ id: service.name })).join(' + '), [event.services]);

  const timeRange = useMemo(() => `${event.startFormatted} - ${event.endFormatted}`, [event]);

  const colorProps = useMemo(() => {
    let colors = {};
    const service = event.services?.[0];

    if (service && service.color) {
      colors = {
        borderColor: service.color.slotBorder,
        backgroundColor: service.color.slotBackground,
        serviceBackgroundColor: service.color.slotIconBackground,
      };
    }

    if (event.relatedSlotId) {
      const colorKeys = Object.keys(HOVER_RELATED_COLORS);
      for (let colorKeyIndex = 0; colorKeyIndex < colorKeys.length; colorKeyIndex += 1) {
        const colorKey = colorKeys[colorKeyIndex];

        colors[colorKey] = `var(--slot-related-${colorKey}-${event.relatedSlotId}, ${colors[colorKey]})`;
      }
    }

    return colors;
  }, [event]);

  return (
    <SlotComponent
      title={title}
      timeRange={timeRange}
      event={event}
      onMouseEnter={() => {
        if (event.relatedSlotId) {
          const colorKeys = Object.keys(HOVER_RELATED_COLORS);
          for (let colorKeyIndex = 0; colorKeyIndex < colorKeys.length; colorKeyIndex += 1) {
            const colorKey = colorKeys[colorKeyIndex];

            setGlobalCSSVariable(`--slot-related-${colorKey}-${event.relatedSlotId}`, HOVER_RELATED_COLORS[colorKey]);
          }
        }
      }}
      onMouseLeave={() => {
        if (event.relatedSlotId) {
          const colorKeys = Object.keys(HOVER_RELATED_COLORS);
          for (let colorKeyIndex = 0; colorKeyIndex < colorKeys.length; colorKeyIndex += 1) {
            const colorKey = colorKeys[colorKeyIndex];

            removeGlobalCSSVariable(`--slot-related-${colorKey}-${event.relatedSlotId}`);
          }
        }
      }}
      {...colorProps}
    />
  );
}
