import { TimeHelper } from '../../../utils';
import { AppointmentFormSlotItem } from '../types';
import { SwapSlot } from '../../../types';

export const createSwapSlotOptions = (swapSlots: SwapSlot[], date: string, timezone: string): AppointmentFormSlotItem[] => {
  const includedOptionMap: Record<string, boolean> = {};
  const nowDjs = TimeHelper.toDayjs(Date.now(), timezone);
  const currentDateString = TimeHelper.toStandardFormat(nowDjs);
  const isToday = date === currentDateString;
  const minutesFromDayStart = TimeHelper.getMinutesFromDayStart(nowDjs);

  const options: AppointmentFormSlotItem[] = [];

  for (let slotIndex = 0; slotIndex < swapSlots.length; slotIndex += 1) {
    const swapSlot = swapSlots[slotIndex];

    if (!includedOptionMap[swapSlot.time]) {
      if (!isToday || swapSlot.time > minutesFromDayStart) {
        options.push({
          value: swapSlot.time,
          label: TimeHelper.getTimeByMinutes(swapSlot.time),
          extra: { isSwap: true },
        });
        includedOptionMap[swapSlot.time] = true;
      }
    }
  }

  return options.sort((a, b) => a.value - b.value);
};
