import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import { alpha, Divider, Stack } from '@mui/material';

import { removeGlobalCSSVariable, ServiceIcon, setGlobalCSSVariable } from '../../commons';
import { AppointmentComponentProps, CustomEventProps } from '../types';
import { LinkedIcon, PinnedIcon } from '../../../assets/icons';
import { CalendarEventStatusENUM } from '../../../types';

import styles from './DayEvent.module.scss';

const HOVER_RELATED_COLORS = {
  borderColor: '#1e3549',
  backgroundColor: '#1e3549',
  dividerColor: alpha('#FFFFFF', 0.15),
  textColor: '#FFFFFF',
};

const genderIntlKeyMap = {
  male: 'common.gender.male',
  female: 'common.gender.female',
};

function DayEventAppointment15({
  borderColor,
  backgroundColor,
  textColor,
  event,
  serviceBackgroundColor,
  timeRange,
  titleShort,
  indicatorColor,
  pinned,
  onMouseEnter,
  onMouseLeave,
}: AppointmentComponentProps) {
  return (
    <div
      style={{ borderColor, backgroundColor, color: textColor }}
      className={clsx(styles.dayEventRoot, styles.dayEventRootDense)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Stack direction="row" spacing={1.5} className={styles.dayEventContent}>
        <Stack direction="row" spacing={0.5}>
          {event.services.map((service) => (
            <div
              key={service.id}
              className={clsx([styles.dayEventServicesItem, styles.dayEventServicesItemDense])}
              style={{ backgroundColor: serviceBackgroundColor }}
            >
              <ServiceIcon icon={service.icon} />
            </div>
          ))}
          {event.splitService && <LinkedIcon className={styles.linkedIcon15} />}
        </Stack>

        <Stack direction="row" spacing={1.5} flex={1} alignItems="center" className={clsx([styles.dayEventBody, styles.dayEventBodyDense])}>
          <span className={clsx(styles.dayEventBodyText, styles.dayEventTimeRange)}>{timeRange}</span>

          <p
            className={clsx(
              styles.dayEventBodyText,
              styles.dayEventBodyTextBold,
              styles.dayEventBodyTextNoWrap,
              styles.dayEventBodyTextFullWidth,
            )}
          >
            {titleShort}
          </p>

          <Stack direction="row" spacing={1}>
            {pinned && (
              <div className={styles.dayEventPinnedIconRoot}>
                <PinnedIcon />
              </div>
            )}

            {indicatorColor && <span className={styles.dayEventIndicator} style={{ backgroundColor: indicatorColor }} />}
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
}

function DayEventAppointment30({
  borderColor,
  backgroundColor,
  textColor,
  event,
  serviceBackgroundColor,
  title,
  indicatorColor,
  timeRange,
  note,
  pinned,
  onMouseEnter,
  onMouseLeave,
}: AppointmentComponentProps) {
  return (
    <div
      style={{ borderColor, backgroundColor, color: textColor }}
      className={clsx(styles.dayEventRoot, styles.dayEventRootCompact)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Stack direction="row" spacing={1.5} className={styles.dayEventContent}>
        <Stack direction="row" spacing={0.5}>
          {event.services.map((service) => (
            <div key={service.id} className={clsx([styles.dayEventServicesItem])} style={{ backgroundColor: serviceBackgroundColor }}>
              <ServiceIcon icon={service.icon} />
            </div>
          ))}
          {event.splitService && <LinkedIcon className={styles.linkedIcon30} />}
        </Stack>

        <Stack direction="column" overflow="hidden" spacing={0.8} flex={1} className={styles.dayEventBody}>
          <Stack direction="row" spacing={1}>
            <p
              className={clsx(
                styles.dayEventBodyText,
                styles.dayEventBodyTextBold,
                styles.dayEventBodyTextNoWrap,
                styles.dayEventBodyTextFullWidth,
              )}
            >
              {title}
            </p>

            {pinned && (
              <div className={styles.dayEventPinnedIconRoot}>
                <PinnedIcon />
              </div>
            )}

            {indicatorColor && <span className={styles.dayEventIndicator} style={{ backgroundColor: indicatorColor }} />}
          </Stack>

          <span className={clsx(styles.dayEventBodyText, styles.dayEventTimeRange)}>{timeRange}</span>

          {note && (
            <>
              <span className={clsx(styles.dayEventBodyText, styles.dayEventBodyTextNoWrap)}>{note}</span>
            </>
          )}
        </Stack>
      </Stack>
    </div>
  );
}

function DayEventAppointment60({
  borderColor,
  backgroundColor,
  textColor,
  title,
  indicatorColor,
  timeRange,
  note,
  dividerColor,
  pinned,
  event,
  serviceBackgroundColor,
  onMouseEnter,
  onMouseLeave,
}: AppointmentComponentProps) {
  return (
    <div
      style={{ borderColor, backgroundColor, color: textColor }}
      className={clsx(styles.dayEventRoot)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Stack direction="row" spacing={1.5} className={styles.dayEventContent}>
        <Stack direction="row" spacing={0.5}>
          {event.services.map((service) => (
            <div key={service.id} className={clsx([styles.dayEventServicesItem])} style={{ backgroundColor: serviceBackgroundColor }}>
              <ServiceIcon icon={service.icon} />
            </div>
          ))}
          {event.splitService && <LinkedIcon className={styles.linkedIcon60} />}
        </Stack>

        <Stack direction="column" spacing={1} flex={1} className={styles.dayEventBody}>
          <Stack direction="row" spacing={1}>
            <p
              className={clsx(
                styles.dayEventBodyText,
                styles.dayEventBodyTextBold,
                styles.dayEventBodyTextNoWrap,
                styles.dayEventBodyTextFullWidth,
              )}
            >
              {title}
            </p>

            {pinned && (
              <div className={styles.dayEventPinnedIconRoot}>
                <PinnedIcon />
              </div>
            )}

            {indicatorColor && <span className={styles.dayEventIndicator} style={{ backgroundColor: indicatorColor }} />}
          </Stack>

          <span className={clsx(styles.dayEventBodyText, styles.dayEventTimeRange)}>{timeRange}</span>

          {note && (
            <>
              <Divider orientation="horizontal" flexItem style={{ borderColor: dividerColor }} />

              <span className={clsx(styles.dayEventBodyText, styles.dayEventBodyTextNoWrap)}>{note}</span>
            </>
          )}
        </Stack>
      </Stack>
    </div>
  );
}

export function DayEventAppointment({ event }: CustomEventProps) {
  const intl = useIntl();

  const AppointmentComponent = useMemo(() => {
    const duration = event.duration || 10;

    if (duration >= 60) {
      return DayEventAppointment60;
    }

    if (duration >= 30) {
      return DayEventAppointment30;
    }

    return DayEventAppointment15;
  }, [event.duration]);

  const title = useMemo(() => {
    const nameArray: string[] = [];
    if (event.customer) {
      if (event.customer.gender && genderIntlKeyMap[event.customer.gender]) {
        nameArray.push(intl.formatMessage({ id: genderIntlKeyMap[event.customer.gender] }));
      }
      if (event.customer.name) {
        nameArray.push(event.customer.name);
      }
      if (event.customer.lastName) {
        nameArray.push(event.customer.lastName);
      }
    }
    return nameArray.join(' ');
  }, [event.customer]);

  const titleShort = useMemo(() => {
    const nameArray: string[] = [];
    if (event.customer) {
      if (event.customer.gender && genderIntlKeyMap[event.customer.gender]) {
        nameArray.push(intl.formatMessage({ id: genderIntlKeyMap[event.customer.gender] }));
      }
      if (event.customer.name) {
        nameArray.push(`${event.customer.name[0]}.`);
      }
      if (event.customer.lastName) {
        nameArray.push(event.customer.lastName);
      }
    }
    return nameArray.join(' ');
  }, [event.customer]);

  const timeRange = useMemo(() => `${event.startFormatted} - ${event.endFormatted}`, [event]);

  const colorProps = useMemo(() => {
    let colors = {};

    const service = event.services?.[0];

    if (event.status === CalendarEventStatusENUM.COMPLETED) {
      colors = {
        borderColor: '#1BB176',
        serviceBackgroundColor: alpha('#FFFFFF', 0.15),
        backgroundColor: '#1BB176',
        dividerColor: alpha('#FFFFFF', 0.15),
        textColor: '#FFFFFF',
      };
    } else if (event.status === CalendarEventStatusENUM.CANCELED) {
      colors = {
        borderColor: '#751f15',
        serviceBackgroundColor: '#7BA7BB',
        backgroundColor: '#751f15',
        dividerColor: alpha('#FFFFFF', 0.15),
        textColor: '#FFFFFF',
      };
    } else if (service && service.color) {
      colors = {
        borderColor: service.color.appointmentBackground,
        serviceBackgroundColor: service.color.appointmentIconBackground,
        backgroundColor: service.color.appointmentBackground,
        dividerColor: service.color.divider,
        textColor: service.color.appointmentText,
      };
    }

    if (event.splitService) {
      const colorKeys = Object.keys(HOVER_RELATED_COLORS);
      for (let colorKeyIndex = 0; colorKeyIndex < colorKeys.length; colorKeyIndex += 1) {
        const colorKey = colorKeys[colorKeyIndex];

        colors[colorKey] = `var(--appointment-related-${colorKey}-${event.id}, ${colors[colorKey]})`;
      }
    }

    return {
      ...colors,
      indicatorColor: event.note ? '#65FFBF' : undefined,
    };
  }, [event]);

  return (
    <AppointmentComponent
      title={title}
      titleShort={titleShort}
      timeRange={timeRange}
      note={event.note}
      pinned={!!event.suggestedEmployee}
      event={event}
      onMouseEnter={() => {
        if (event.splitService) {
          const colorKeys = Object.keys(HOVER_RELATED_COLORS);
          for (let colorKeyIndex = 0; colorKeyIndex < colorKeys.length; colorKeyIndex += 1) {
            const colorKey = colorKeys[colorKeyIndex];

            setGlobalCSSVariable(
              `--appointment-related-${colorKey}-${event.id}`,
              HOVER_RELATED_COLORS[colorKey],
            );
          }
        }
      }}
      onMouseLeave={() => {
        if (event.splitService) {
          const colorKeys = Object.keys(HOVER_RELATED_COLORS);
          for (let colorKeyIndex = 0; colorKeyIndex < colorKeys.length; colorKeyIndex += 1) {
            const colorKey = colorKeys[colorKeyIndex];

            removeGlobalCSSVariable(`--appointment-related-${colorKey}-${event.id}`);
          }
        }
      }}
      {...colorProps}
    />
  );
}
