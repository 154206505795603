import React from 'react';
import Button from '@mui/material/Button';
import { useIntl } from 'react-intl';
import ReactGA from 'react-ga4';

import { Plus } from '@linetweet/linetweet-ui';

import styles from './CreateAppointmentButton.module.scss';

type Props = {
  onClick?: () => void;
};

export function CreateAppointmentButton({ onClick }: Props) {
  const intl = useIntl();
  return (
    <Button
      className={styles.createAppointmentButton}
      variant="contained"
      color="primary"
      onClick={() => {
        ReactGA.event({
          category: 'Appointment',
          action: 'Create',
        });

        if (onClick) {
          onClick();
        }
      }}
      startIcon={<Plus />}
    >
      {intl.formatMessage({ id: 'calendar.createAppointment' })}
    </Button>
  );
}

CreateAppointmentButton.defaultProps = {
  onClick: undefined,
};
