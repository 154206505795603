import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';

import { Close as CloseIcon, DialogSection, IOption, Notice, NoticeStack, Nullable } from '@linetweet/linetweet-ui';
import {
  Backdrop,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  IconButton,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';

import { isArrayEqual, TimeHelper } from 'utils';
import { AsyncButton, EmployeeSelectField, GetAppointmentCustomer, GetAppointmentServices, Spin } from 'features/commons';
import { Appointment, AppointmentEvent, CalendarEventTypeEnum, IUpdateCalendarEventQueryParams, Service, Store } from 'types';
import { deleteCalendarEvents, getCalendarEvent, updateCalendarEvents } from 'store/appointments/thunks';
import { getSlots, getSlotsByDate, getSwapSlots } from 'store/slots/thunks';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { yupResolver } from '@hookform/resolvers/yup';

import { shallowEqual } from 'react-redux';
import { selectAvailableDates } from 'store/slots/selectors';
import { resetDaySlots, resetSlots, resetSwapSlots } from 'store/slots/slice';
import { IGetSlotsByDatesPayload } from 'store/slots/types';
import {
  AppointmentFormCustomerSection,
  AppointmentFormInformationSection,
  AppointmentFormServiceSection,
  AppointmentFormTimeSection,
  SlotSelectSection,
} from './components';
import {
  AppointmentFormSlotItem,
  CalendarEventCreateParams,
  EditCalendarEventFormProps,
  IAppointmentFormValues,
  IBlockerFormValues,
  IBreakFormValues,
  ServiceTabsSelectOption,
} from './types';
import {
  calendarEventToCalendarCreateValues,
  createSlotOptions,
  getDateRange,
} from './helpers';
import { FormDatePicker } from './subcomponents';
import { selectAvailableEmployees, selectSlotEmployeesByServiceIds } from '../../store/employees/selectors';
import { selectStoreServices } from '../../store/store/selectors';
import { isRequired } from './util/isRequired';
import { fetchEmployees } from '../../store/employees/thunks';
import { resetUpdateCalendarEvent } from '../../store/appointments/slice';
import { appointmentFormSchema } from './util/appointmentFormSchema';
import { breakFormSchema } from './util/breakFormSchema';
import { blockerFormSchema } from './util/blockerFormSchema';
import { createEmployeeOptions } from './util/createEmployeeOptions';
import { createSwapSlotOptions } from './util/createSwapSlotOptions';

import styles from './EditCalendarEvent.module.scss';

type Props = {
  appointmentId: string;
  type: CalendarEventTypeEnum;
  employeeId?: string;
  onClose: () => void;
  onCopyToNew: (params: CalendarEventCreateParams) => void;
  shouldReload?: boolean;
  isDisabled?: boolean;
};

const calendarEventToAppointmentFormValues = (
  calendarEvent: Appointment,
  allServices: Service[],
  intl: IntlShape,
): IAppointmentFormValues => {
  const customer = GetAppointmentCustomer(calendarEvent);
  const services: Service[] = GetAppointmentServices(calendarEvent, allServices);

  return {
    date: calendarEvent.date,
    note: calendarEvent.note || null,
    slot: {
      label: TimeHelper.getTimeByMinutes(calendarEvent.time),
      value: calendarEvent.time,
    },
    gender: customer?.gender || null,
    services: services.map(
      (service): ServiceTabsSelectOption => ({
        label: intl.formatMessage({ id: service.name }),
        value: service.id,
        name: service.name,
        tabId: service.category,
        extra: { ...service },
      }),
    ),
    employee: (calendarEvent.eventTypeData as AppointmentEvent).suggestedEmployee || null,
    title: calendarEvent.title || null,
    lastName: customer?.lastName || '',
    firstName: customer?.name || '',
    email: customer?.email || '',
    phoneNumber: customer?.phoneNumber || '',
    pinnedEmployee: calendarEvent?.pinnedEmployee || '',
  };
};

const calendarEventToBreakFormValues = (
  calendarEvent: Appointment,
): IBreakFormValues => ({
  date: calendarEvent.date,
  note: calendarEvent.note || null,
  employee: (calendarEvent.eventTypeData as AppointmentEvent).suggestedEmployee || null,
  title: calendarEvent.title || null,
  recurring: null,
  from: calendarEvent.time,
  to: calendarEvent.time + (calendarEvent.duration || 0),
});

const calendarEventToBlockerFormValues = (
  calendarEvent: Appointment,
): IBlockerFormValues => ({
  date: calendarEvent.date,
  note: calendarEvent.note || null,
  employee: (calendarEvent.eventTypeData as AppointmentEvent).suggestedEmployee || null,
  title: calendarEvent.title || null,
  from: calendarEvent.time,
  to: calendarEvent.time + (calendarEvent.duration || 0),
});

function EditAppointmentForm({ appointment, onCopy, onClose, onDelete, isDisabled, onSubmit: propsOnSubmit }: EditCalendarEventFormProps) {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const allServices = useAppSelector(selectStoreServices, shallowEqual);

  const form = useForm<IAppointmentFormValues>({
    defaultValues: calendarEventToAppointmentFormValues(appointment, allServices, intl),
    resolver: yupResolver(appointmentFormSchema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const servicesValue = useWatch({ control: form.control, name: 'services' });
  const dateValue = useWatch({ control: form.control, name: 'date' });
  const employeeValue = useWatch({ control: form.control, name: 'employee' });
  const slotValue = useWatch({ control: form.control, name: 'slot' });

  const serviceIds = useMemo(() => servicesValue.map(({ value }) => value), [servicesValue]);

  const storeState = useAppSelector((state) => state.store);
  const calendarSlotsState = useAppSelector((state) => state.slots.slots, shallowEqual);
  const daySlotsState = useAppSelector((state) => state.slots.dateSlots, shallowEqual);
  const updateCalendarEventState = useAppSelector((state) => state.appointments.updateCalendarEvent);
  const availableDays = useAppSelector((state) => selectAvailableDates(state, serviceIds, employeeValue), shallowEqual);
  const slotEmployees = useAppSelector((state) => selectSlotEmployeesByServiceIds(state, serviceIds, dateValue), shallowEqual);
  const availableEmployees = useAppSelector((state) => selectAvailableEmployees(state, serviceIds, dateValue), shallowEqual);
  const swapSlotsState = useAppSelector((state) => state.slots.swapSlots, shallowEqual);

  const timeZone = useAppSelector((state) => state.store.data?.timeZone || 'Europe/Berlin', shallowEqual);

  const onSubmit = form.handleSubmit(async (values) => {
    const serviceValues = values.services.map((serviceOption) => serviceOption.value);

    let time = 0;
    let swap;
    if (values.slot && values.slot.value) {
      time = values.slot.value;
      if (values.slot.extra && values.slot.extra.isSwap) {
        swap = true;
      }
    }

    let duration: number = values.services.reduce((acc, service) => acc + service.extra.duration, 0);
    if (duration !== appointment.duration) {
      const services = values.services.map((service) => service.value);
      if (
        isArrayEqual(services, (appointment.eventTypeData as AppointmentEvent).services) &&
        appointment.time === time &&
        appointment.date === values.date
      ) {
        // if services are equal and start time is equal as well, we keep appointment initial duration
        duration = appointment.duration;
      }
    }

    const title: Nullable<string> = [values.firstName, values.lastName].join(' ').trim();

    const params: IUpdateCalendarEventQueryParams = {
      duration,
      date: values.date,
      time,
      swap,
      type: CalendarEventTypeEnum.APPOINTMENT,
      title,
      note: values.note || '',
      eventTypeData: {
        services: serviceValues,
        suggestedEmployee: typeof values.employee !== 'undefined' ? values.employee : undefined,
        customerId: (appointment.eventTypeData as AppointmentEvent).customerId,
        customer: {
          name: values.firstName,
          lastName: values.lastName,
          phoneNumber: values.phoneNumber,
          email: values.email,
          gender: typeof values.gender !== 'undefined' ? values.gender : undefined,
          locale: 'en',
        },
      },
    };

    await propsOnSubmit(params);
  });

  const slotOptions = useMemo(() => {
    const isRelatedFieldsDirty = form.formState.dirtyFields.date || form.formState.dirtyFields.services;

    let defaultSlot;
    // eslint-disable-next-line no-underscore-dangle
    if (!isRelatedFieldsDirty && appointment && form.control._defaultValues.slot) {
      const defaultEmployeeId = appointment.pinnedEmployee;
      if (defaultEmployeeId && (!employeeValue || employeeValue === defaultEmployeeId)) {
        defaultSlot = {
          // eslint-disable-next-line no-underscore-dangle
          ...form.control._defaultValues.slot,
          pinnedEmployee: appointment.pinnedEmployee,
        };
      }
    }
    return createSlotOptions({
      timeZone,
      employeeId: employeeValue,
      slots: daySlotsState.data || [],
      defaultSlot,
      previousEmployeeId: form.formState.defaultValues?.employee,
      pinnedEmployee: defaultSlot?.pinnedEmployee,
    });
  }, [daySlotsState, timeZone, employeeValue, appointment]);

  const swapSlotOptions = useMemo<AppointmentFormSlotItem[] | undefined>(() => (
    swapSlotsState.data ? createSwapSlotOptions(swapSlotsState.data, dateValue, timeZone) : undefined
  ), [swapSlotsState.data, dateValue, timeZone]);

  const employeeOptions = useMemo(() => (
    createEmployeeOptions({
      employeeId: employeeValue,
      storeEmployees: storeState.data && storeState.data.employees,
      availableEmployees,
    })
  ), [availableEmployees, employeeValue]);

  const fetchSwapOptions = useCallback(() => {
    if (storeState.data && storeState.data.settings.swap && employeeValue) {
      dispatch(getSwapSlots({
        date: dateValue,
        employeeId: employeeValue,
        serviceIds,
        timestamp: Date.now(),
        timezone: timeZone,
      }));
    }
  }, [employeeValue, dateValue, employeeValue, serviceIds, timeZone, storeState.data]);

  useEffect(() => {
    if (updateCalendarEventState.error) {
      dispatch(getSlotsByDate({
        serviceIds,
        date: dateValue,
        timestamp: Date.now(),
        timezone: timeZone,
      }));

      if (swapSlotsState.data) {
        fetchSwapOptions();
      }
    }
  }, [updateCalendarEventState.error]);

  useEffect(() => {
    if (daySlotsState.loading) {
      return;
    }

    let slotToSet: AppointmentFormSlotItem | null = null;

    const optionByValueMap: Record<number, IOption> = {};
    for (let optionIndex = 0; optionIndex < slotOptions.length; optionIndex += 1) {
      const option = slotOptions[optionIndex];
      optionByValueMap[option.value] = option;
    }

    const possibleValues: AppointmentFormSlotItem[] = [];
    if (slotValue) {
      possibleValues.push(slotValue);
    }

    // eslint-disable-next-line no-underscore-dangle
    if (form.control._defaultValues.slot) {
      // eslint-disable-next-line no-underscore-dangle
      possibleValues.push(form.control._defaultValues.slot as AppointmentFormSlotItem);
    }

    const possibleValue = possibleValues.find((slotOption) => optionByValueMap[slotOption.value]);

    if (possibleValue) {
      slotToSet = possibleValue;
    }

    form.setValue('slot', slotToSet, { shouldValidate: true });
  }, [slotOptions]);

  useEffect(() => {
    if (
      !isDisabled &&
      !daySlotsState.loading &&
      daySlotsState.data &&
      employeeValue &&
      !slotValue &&
      (!slotEmployees.find(({ id }) => id === employeeValue) || !slotOptions.length)
    ) {
      form.setError('employee', {
        type: 'employee-no-slots',
        message: intl.formatMessage({ id: 'calendarEvent.employee.noSlotsAvailableError' }),
      });
      return;
    }

    form.clearErrors('employee');
  }, [slotEmployees, slotValue, employeeValue, daySlotsState.loading, slotOptions, isDisabled]);

  const prevSlotsPayload = useRef<Nullable<IGetSlotsByDatesPayload>>(null);
  useEffect(() => {
    const payload = {
      serviceIds: allServices
        .filter((service: Service) => serviceIds.includes(service.id))
        .map((service) => service.id)
        .sort(),
      dates: getDateRange(dateValue, timeZone),
      timestamp: Date.now(),
      timezone: timeZone,
    };

    let shouldFetch = !prevSlotsPayload.current;

    if (!shouldFetch && prevSlotsPayload.current!.dates[0] !== payload.dates[0]) {
      shouldFetch = true;
    }

    if (!shouldFetch && payload.serviceIds.length !== prevSlotsPayload.current!.serviceIds.length) {
      shouldFetch = true;
    }

    if (!shouldFetch) {
      for (let index = 0; index < payload.serviceIds.length; index += 1) {
        const payloadServiceId = payload.serviceIds[index];
        const prevPayloadServiceId = prevSlotsPayload.current!.serviceIds[index];

        if (payloadServiceId !== prevPayloadServiceId) {
          shouldFetch = true;
          break;
        }
      }
    }

    if (shouldFetch) {
      dispatch(getSlots(payload));

      prevSlotsPayload.current = payload;
    }
  }, [dateValue, serviceIds, allServices, timeZone]);

  useEffect(() => {
    dispatch(
      getSlotsByDate({
        serviceIds: allServices
          .filter((service: Service) => serviceIds.includes(service.id))
          .map((service) => service.id)
          .sort(),
        date: dateValue,
        timestamp: Date.now(),
        timezone: timeZone,
      }),
    );
  }, [dateValue, serviceIds, allServices]);

  useEffect(() => {
    if (dateValue) {
      const from = TimeHelper.toDayjs(dateValue, timeZone);
      dispatch(fetchEmployees({ from }));
    }
  }, [dateValue, timeZone]);

  useEffect(() => {
    dispatch(resetSwapSlots());
  }, [dateValue, servicesValue, employeeValue]);

  useEffect(
    () => () => {
      dispatch(resetDaySlots());
      dispatch(resetSlots());
      dispatch(resetSwapSlots());
    },
    [],
  );

  return (
    <FormProvider {...form}>
      <form>
        <Spin loading={calendarSlotsState.loading}>
          <Stack direction="column" spacing={1.5}>
            <Box>
              <AppointmentFormCustomerSection size="small" isDisabled={isDisabled} schema={appointmentFormSchema} />
            </Box>

            {Boolean(servicesValue.length) && (
              <Controller
                control={form.control}
                name="slot"
                render={({ field }) => (
                  <SlotSelectSection
                    value={field.value}
                    onChange={(nextValue) => {
                      field.onChange(nextValue);
                      form.trigger('slot');
                    }}
                    options={slotOptions}
                    loading={daySlotsState.loading}
                    isWarning={!!form.formState.errors.slot}
                    isDisabled={!!isDisabled}
                    before={(
                      <Controller
                        name="date"
                        render={({ field: dateField }) => (
                          <FormDatePicker
                            isDisabled={!!isDisabled}
                            date={dateField.value}
                            suggestedDays={availableDays}
                            onChange={dateField.onChange}
                          />
                        )}
                      />
                    )}
                    employeeId={employeeValue}
                    swapEnabled={storeState.data && storeState.data.settings.swap}
                    swapOptions={swapSlotOptions}
                    onFetchSwapOptionsClick={fetchSwapOptions}
                    swapOptionsLoading={swapSlotsState.loading}
                    swapOptionsError={!!swapSlotsState.error}
                  />
                )}
              />
            )}

            <AppointmentFormServiceSection services={allServices} isDisabled={!!isDisabled} />

            <DialogSection title={intl.formatMessage({ id: 'appointment.specificEmployee' })}>
              <Controller
                control={form.control}
                name="employee"
                render={({ field, fieldState }) => (
                  <EmployeeSelectField
                    values={employeeOptions}
                    disabled={!!isDisabled}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    helperText={
                      !['nullable', 'required'].includes(fieldState.error?.type as string) ? fieldState.error?.message : undefined
                    }
                    required={isRequired(appointmentFormSchema, field.name)}
                  />
                )}
              />
            </DialogSection>

            <Stack direction="row" alignItems="center" justifyContent="space-between">
              {isDisabled ? (
                <Button data-testid="copy-to-new-button" variant="outlined" onClick={onCopy} disabled={!appointment}>
                  {intl.formatMessage({ id: 'appointment.copyToNew' })}
                </Button>
              ) : (
                <Stack direction="column" spacing={1} flex={1}>
                  <Stack direction="row">
                    <Button data-testid="delete-button" variant="outlined" onClick={onDelete}>
                      {intl.formatMessage({ id: 'common.delete' })}
                    </Button>
                    <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1.5} flex={1}>
                      <Button data-testid="abort-button" variant="outlined" onClick={onClose}>
                        {intl.formatMessage({ id: 'common.abort' })}
                      </Button>
                      <AsyncButton data-testid="save-button" variant="contained" onClick={onSubmit}>
                        {intl.formatMessage({ id: 'common.save' })}
                      </AsyncButton>
                    </Stack>
                  </Stack>

                  {form.formState.isSubmitted && !(form.formState.isValid && !Object.keys(form.formState.errors).length) && (
                    <FormHelperText error>{intl.formatMessage({ id: 'common.formHasErrors' })}</FormHelperText>
                  )}
                </Stack>
              )}
            </Stack>
          </Stack>
        </Spin>
      </form>
    </FormProvider>
  );
}

function EditBreakForm({ appointment, onCopy, onClose, onDelete, isDisabled, onSubmit: propsOnSubmit }: EditCalendarEventFormProps) {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const form = useForm<IBreakFormValues>({
    defaultValues: calendarEventToBreakFormValues(appointment),
    resolver: yupResolver(breakFormSchema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const dateValue = useWatch({ control: form.control, name: 'date' });
  const employeeValue = useWatch({ control: form.control, name: 'employee' });

  const storeState = useAppSelector((state) => state.store);
  const calendarSlotsState = useAppSelector((state) => state.slots.slots, shallowEqual);
  const availableEmployees = useAppSelector((state) => selectAvailableEmployees(state, [], dateValue), shallowEqual);

  const onSubmit = form.handleSubmit(async (values: IBreakFormValues) => {
    let time = 0;
    let duration: number | undefined;
    if (values.from != null && values.to != null) {
      time = values.from;
      duration = values.to - values.from;
    }

    let title: string | undefined;
    if (values.title != null) {
      title = values.title;
    }

    const params: IUpdateCalendarEventQueryParams = {
      date: values.date,
      time,
      type: CalendarEventTypeEnum.BREAK,
      title,
      note: values.note || '',
      duration,
      eventTypeData: {
        suggestedEmployee: typeof values.employee !== 'undefined' ? values.employee : undefined,
      },
    };

    await propsOnSubmit(params);
  });

  const timeZone = useMemo(() => storeState.data?.timeZone || 'Europe/Berlin', [storeState]);

  const employeeOptions = useMemo(() => (
    createEmployeeOptions({
      employeeId: employeeValue,
      storeEmployees: storeState.data && storeState.data.employees,
      availableEmployees,
    })
  ), [availableEmployees, employeeValue]);

  useEffect(() => {
    if (dateValue) {
      const from = TimeHelper.toDayjs(dateValue, timeZone);
      dispatch(fetchEmployees({ from }));
    }
  }, [dateValue, timeZone]);

  return (
    <FormProvider {...form}>
      <form>
        <Spin loading={calendarSlotsState.loading}>
          <Stack direction="column" spacing={1.5}>
            <AppointmentFormInformationSection isDisabled={isDisabled} />

            <DialogSection title={intl.formatMessage({ id: 'appointment.date' })}>
              <Controller
                name="date"
                render={({ field }) => <FormDatePicker date={field.value} onChange={field.onChange} isDisabled={!!isDisabled} />}
              />

              <AppointmentFormTimeSection isDisabled={!!isDisabled} />
            </DialogSection>

            <DialogSection title={intl.formatMessage({ id: 'appointment.specificEmployee' })}>
              <Controller
                control={form.control}
                name="employee"
                render={({ field, fieldState }) => (
                  <EmployeeSelectField
                    values={employeeOptions}
                    disabled={!!isDisabled}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    helperText={
                      !['nullable', 'required'].includes(fieldState.error?.type as string) ? fieldState.error?.message : undefined
                    }
                    required={isRequired(appointmentFormSchema, field.name)}
                  />
                )}
              />
            </DialogSection>

            <Stack direction="row" alignItems="center" justifyContent="space-between">
              {isDisabled ? (
                <Button data-testid="copy-to-new-button" variant="outlined" onClick={onCopy} disabled={!appointment}>
                  {intl.formatMessage({ id: 'appointment.copyToNew' })}
                </Button>
              ) : (
                <Stack direction="column" spacing={1} flex={1}>
                  <Stack direction="row">
                    <Button data-testid="delete-button" variant="outlined" onClick={onDelete}>
                      {intl.formatMessage({ id: 'common.delete' })}
                    </Button>
                    <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1.5} flex={1}>
                      <Button data-testid="abort-button" variant="outlined" onClick={onClose}>
                        {intl.formatMessage({ id: 'common.abort' })}
                      </Button>
                      <AsyncButton data-testid="save-button" variant="contained" onClick={onSubmit}>
                        {intl.formatMessage({ id: 'common.save' })}
                      </AsyncButton>
                    </Stack>
                  </Stack>

                  {form.formState.isSubmitted && !(form.formState.isValid && !Object.keys(form.formState.errors).length) && (
                    <FormHelperText error>{intl.formatMessage({ id: 'common.formHasErrors' })}</FormHelperText>
                  )}
                </Stack>
              )}
            </Stack>
          </Stack>
        </Spin>
      </form>
    </FormProvider>
  );
}

function EditBlockerForm({ appointment, onCopy, onClose, onDelete, isDisabled, onSubmit: propsOnSubmit }: EditCalendarEventFormProps) {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const form = useForm<IBlockerFormValues>({
    defaultValues: calendarEventToBlockerFormValues(appointment),
    resolver: yupResolver(blockerFormSchema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const dateValue = useWatch({ control: form.control, name: 'date' });
  const employeeValue = useWatch({ control: form.control, name: 'employee' });

  const storeState = useAppSelector((state) => state.store);
  const calendarSlotsState = useAppSelector((state) => state.slots.slots, shallowEqual);
  const availableEmployees = useAppSelector((state) => selectAvailableEmployees(state, [], dateValue), shallowEqual);

  const onSubmit = form.handleSubmit(async (values) => {
    let time = 0;
    let duration: number | undefined;
    if (values.from != null && values.to != null) {
      time = values.from;
      duration = values.to - values.from;
    }

    let title: string | undefined;
    if (values.title != null) {
      title = values.title;
    }

    const params: IUpdateCalendarEventQueryParams = {
      date: values.date,
      time,
      type: CalendarEventTypeEnum.BLOCKER,
      title,
      note: values.note || '',
      duration,
      eventTypeData: {
        suggestedEmployee: typeof values.employee !== 'undefined' ? values.employee : undefined,
      },
    };

    await propsOnSubmit(params);
  });

  const timeZone = useMemo(() => storeState.data?.timeZone || 'Europe/Berlin', [storeState]);

  const employeeOptions = useMemo(() => (
    createEmployeeOptions({
      employeeId: employeeValue,
      storeEmployees: storeState.data && storeState.data.employees,
      availableEmployees,
    })
  ), [availableEmployees, employeeValue]);

  useEffect(() => {
    if (dateValue) {
      const from = TimeHelper.toDayjs(dateValue, timeZone);
      dispatch(fetchEmployees({ from }));
    }
  }, [dateValue, timeZone]);

  return (
    <FormProvider {...form}>
      <form>
        <Spin loading={calendarSlotsState.loading}>
          <Stack direction="column" spacing={1.5}>
            <AppointmentFormInformationSection isDisabled={isDisabled} />

            <DialogSection title={intl.formatMessage({ id: 'appointment.date' })}>
              <Controller
                name="date"
                render={({ field }) => <FormDatePicker date={field.value} onChange={field.onChange} isDisabled={!!isDisabled} />}
              />

              <AppointmentFormTimeSection isDisabled={!!isDisabled} />
            </DialogSection>

            <DialogSection title={intl.formatMessage({ id: 'appointment.specificEmployee' })}>
              <Controller
                control={form.control}
                name="employee"
                render={({ field, fieldState }) => (
                  <EmployeeSelectField
                    values={employeeOptions}
                    disabled={!!isDisabled}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!fieldState.error}
                    helperText={
                      !['nullable', 'required'].includes(fieldState.error?.type as string) ? fieldState.error?.message : undefined
                    }
                    required={isRequired(appointmentFormSchema, field.name)}
                  />
                )}
              />
            </DialogSection>

            <Stack direction="row" alignItems="center" justifyContent="space-between">
              {isDisabled ? (
                <Button data-testid="copy-to-new-button" variant="outlined" onClick={onCopy} disabled={!appointment}>
                  {intl.formatMessage({ id: 'appointment.copyToNew' })}
                </Button>
              ) : (
                <Stack direction="column" spacing={1} flex={1}>
                  <Stack direction="row">
                    <Button data-testid="delete-button" variant="outlined" onClick={onDelete}>
                      {intl.formatMessage({ id: 'common.delete' })}
                    </Button>
                    <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1.5} flex={1}>
                      <Button data-testid="abort-button" variant="outlined" onClick={onClose}>
                        {intl.formatMessage({ id: 'common.abort' })}
                      </Button>
                      <AsyncButton data-testid="save-button" variant="contained" onClick={onSubmit}>
                        {intl.formatMessage({ id: 'common.save' })}
                      </AsyncButton>
                    </Stack>
                  </Stack>

                  {form.formState.isSubmitted && !(form.formState.isValid && !Object.keys(form.formState.errors).length) && (
                    <FormHelperText error>{intl.formatMessage({ id: 'common.formHasErrors' })}</FormHelperText>
                  )}
                </Stack>
              )}
            </Stack>
          </Stack>
        </Spin>
      </form>
    </FormProvider>
  );
}

export function EditCalendarEvent({ appointmentId, employeeId, onClose, onCopyToNew, shouldReload, isDisabled, type }: Props) {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const appointmentsState = useAppSelector((state) => state.appointments);
  const storeState = useAppSelector((state) => state.store);
  const calendarSlotsState = useAppSelector((state) => state.slots.slots, shallowEqual);
  const daySlotsState = useAppSelector((state) => state.slots.dateSlots, shallowEqual);
  const allServices = useAppSelector(selectStoreServices, shallowEqual);

  const timeZone = useMemo<string>(() => storeState.data?.timeZone || 'Europe/Berlin', [storeState]);

  const [calendarEvent, setCalendarEvent] = useState<Appointment | undefined>(undefined);
  const [store, setStore] = useState<Store | undefined>();
  const [isConfirmationDialogOpened, setIsConfirmationDialogOpened] = useState<boolean>(false);
  const [slotsErrorShown, setSlotsErrorShown] = useState(false);

  useEffect(() => {
    if (appointmentsState.loading) {
      return;
    }

    if (shouldReload) {
      if (!appointmentsState.singleAppointment) {
        dispatch(getCalendarEvent({ appointmentId }));
      } else if (appointmentsState.singleAppointment.id === appointmentId) {
        setCalendarEvent(appointmentsState.singleAppointment);
      }
    } else {
      let nextCalendarEvent = appointmentsState.data.find((appointmentItem) => appointmentItem.id === appointmentId);
      if (nextCalendarEvent && calendarEvent?.id !== nextCalendarEvent?.id) {
        const { overrides } = nextCalendarEvent;
        if (overrides) {
          const overridenNextAppointment = {
            ...nextCalendarEvent,
            time: overrides.time,
            duration: overrides.duration,
            eventTypeData: {
              ...nextCalendarEvent.eventTypeData,
              services: overrides.services,
              pinnedEmployee: overrides.pinnedEmployee,
            },
          };
          nextCalendarEvent = overridenNextAppointment;
        }
        setCalendarEvent(nextCalendarEvent);
      }
    }
  }, [appointmentsState, calendarEvent, appointmentId]);

  useEffect(() => {
    if (storeState.loading || storeState.error) {
      return;
    }
    setStore(storeState.data);
  }, [storeState]);

  const onCopy = useCallback(() => {
    if (calendarEvent) {
      const appointmentParams = calendarEventToCalendarCreateValues(calendarEvent, allServices, employeeId, timeZone);
      onCopyToNew(appointmentParams);
      onClose();
    }
  }, [calendarEvent, allServices, employeeId]);

  const openDeleteConfirmationDialog = useCallback(() => {
    setIsConfirmationDialogOpened(true);
  }, []);

  const closeDeleteConfirmationDialog = useCallback(() => {
    setIsConfirmationDialogOpened(false);
  }, []);

  const handleDelete = useCallback(async () => {
    if (!calendarEvent) return;

    dispatch(deleteCalendarEvents({ id: calendarEvent.id, reason: 'delete reason' }) as any);
    onClose();
  }, [calendarEvent]);

  const onSubmit = useCallback(async (params: IUpdateCalendarEventQueryParams) => {
    if (!store || !calendarEvent) return;

    try {
      const result = await dispatch(updateCalendarEvents({ appointmentId: calendarEvent.id, params }));

      if (updateCalendarEvents.fulfilled.match(result)) {
        onClose();
      }
    } catch (error) { /* empty */ }
  }, [store, calendarEvent]);

  useEffect(() => {
    setSlotsErrorShown(Boolean(calendarSlotsState.error || daySlotsState.error));
  }, [calendarSlotsState.error, daySlotsState.error]);

  useEffect(
    () => () => {
      dispatch(resetUpdateCalendarEvent());
    },
    [],
  );

  if (!calendarEvent || !store) {
    return <></>;
  }

  return (
    <>
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open onClick={onClose}>
        <Paper
          data-testid="edit-appointment-paper"
          className={styles.formWrapper}
          onClick={(event) => event.stopPropagation()}
        >
          <Stack direction="column" spacing={1.5}>
            <Stack direction="row" justifyContent="space-between">
              <Typography className={styles.formTitle}>
                {intl.formatMessage(
                  { id: 'common.editEntity' },
                  { entity: intl.formatMessage({ id: `appointment.${calendarEvent.type}` }) },
                )}
              </Typography>
              <IconButton
                data-testid="edit-calendar-event-close-button"
                size="small"
                className={styles.actionIconButton}
                onClick={onClose}
              >
                <CloseIcon className={styles.actionIcon} />
              </IconButton>
            </Stack>
            { type === CalendarEventTypeEnum.APPOINTMENT && (
              <EditAppointmentForm
                appointment={calendarEvent}
                isDisabled={isDisabled}
                onClose={onClose}
                onCopy={onCopy}
                onDelete={openDeleteConfirmationDialog}
                onSubmit={onSubmit}
              />
            ) }
            { type === CalendarEventTypeEnum.BREAK && (
              <EditBreakForm
                appointment={calendarEvent}
                isDisabled={isDisabled}
                onClose={onClose}
                onCopy={onCopy}
                onDelete={openDeleteConfirmationDialog}
                onSubmit={onSubmit}
              />
            ) }
            { type === CalendarEventTypeEnum.BLOCKER && (
              <EditBlockerForm
                appointment={calendarEvent}
                isDisabled={isDisabled}
                onClose={onClose}
                onCopy={onCopy}
                onDelete={openDeleteConfirmationDialog}
                onSubmit={onSubmit}
              />
            ) }
          </Stack>
        </Paper>
      </Backdrop>

      <Dialog open={isConfirmationDialogOpened}>
        <DialogTitle>{intl.formatMessage({ id: 'calendar.deleteAppointmentDialogTitle' })}</DialogTitle>
        <DialogContent>{intl.formatMessage({ id: 'calendar.deleteAppoinctmentDialog' })}</DialogContent>
        <DialogActions>
          <Button data-testid="delete-dialog-close" autoFocus onClick={closeDeleteConfirmationDialog}>
            {intl.formatMessage({ id: 'common.abort' })}
          </Button>
          <Button
            data-testid="delete-dialog-confirm"
            onClick={handleDelete}>
            {intl.formatMessage({ id: 'common.confirm' })}
          </Button>
        </DialogActions>
      </Dialog>

      <NoticeStack>
        <Notice
          open={slotsErrorShown}
          onClose={() => setSlotsErrorShown(false)}
          severity="error"
          autoHideDuration={60000}
          title={intl.formatMessage({ id: 'slot.slotsFetchErrorTitle' })}
        />
      </NoticeStack>
    </>
  );
}

EditCalendarEvent.defaultProps = {
  employeeId: undefined,
  shouldReload: undefined,
  isDisabled: undefined,
};
