import React from 'react';
import { useIntl } from 'react-intl';

import { ResyncDialog } from './ResyncDialog';

type EpepResyncDialogProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  submitting: boolean;
  employeeSource: string;
};

export function EpepResyncDialog(props) {
  const intl = useIntl();
  const { employeeSource, ...rest } = props;

  return (
    <ResyncDialog
      {...rest}
      title={intl.formatMessage({ id: 'menu.confirmEpepResyncTitle' }, { source: employeeSource })}
      content={intl.formatMessage({ id: 'menu.confirmEpepResyncContent' }, { source: employeeSource })}
    />
  );
}
