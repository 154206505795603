import React from 'react';
import { Stack, styled } from '@mui/material';
import clsx from 'clsx';
import { ButtonSecondary } from '@linetweet/linetweet-ui';
import { useIntl } from 'react-intl';
import ReactGA from 'react-ga4';

import styles from './DayOverview.module.scss';
import { DayOverviewType } from '../../../types';

type Props = {
  onSeeMore: () => void;
  daySummary: DayOverviewType;
};

const MoreButton = styled(ButtonSecondary)(() => ({
  fontSize: '14px',
  lineHeight: '1',
  padding: '4px 6px',
  backgroundColor: '#F6F6F8',
}));

export function DayOverview(props: Props) {
  const { onSeeMore, daySummary } = props;

  const intl = useIntl();

  if (!daySummary || daySummary.weekEnd || !daySummary.overview) {
    return <></>;
  }
  let totalLength = 0;
  const itemsToAdd: any = [];
  daySummary.overview.forEach((summary) => {
    if (!summary.isWalkin) {
      if (totalLength < 35) {
        const text = summary.labels.map((label: any) => intl.formatMessage({ id: label })).join('+');
        totalLength += text.length;
        itemsToAdd.push(
          <div
            key={summary.labels.join('-')}
            className={clsx({
              [styles.overview_item]: true,
              [styles.error]: summary.color === 'error',
            })}
          >
            <span className={styles.item_value}>{summary.value}</span>
            {text}
          </div>,
        );
      }
    }
  });

  return (
    <Stack ml={2} direction="row" alignItems="center" spacing={1}>
      {itemsToAdd}
      <MoreButton onClick={() => {
        if (onSeeMore) {
          onSeeMore();
        }

        ReactGA.event({
          category: 'DayOverview',
          action: 'SeeMore',
        });
      }}
      >
        {intl.formatMessage({ id: 'common.more' })}
      </MoreButton>
    </Stack>
  );
}
