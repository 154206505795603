import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Box, Stack, Typography, InputBase } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { debounce } from 'lodash';
import { shallowEqual } from 'react-redux';

import { Close as CloseIcon, NewSearch as SearchIcon, ArrowLeft as BackIcon } from '@linetweet/linetweet-ui';

import { SearchCalendarEvent } from '../../types';
import { searchCalendarEvents } from '../../store/appointments/thunks';
import { resetSearch } from '../../store/appointments/slice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { TimeHelper } from '../../utils';

import styles from './index.module.scss';
import { SearchItem } from './subcomponents/SearchItem';
import { VirtualList } from '../commons';
import { selectStoreServices } from '../../store/store/selectors';

export type SearchFormProps = {
  onClick: (params: { searchCalendarEvent: SearchCalendarEvent; isPastItem: boolean }) => void;
};

const SEARCH_ITEMS_PER_PAGE = 6;

export function SearchForm({ onClick }: SearchFormProps) {
  const appointmentsState = useAppSelector((state) => state.appointments);
  const storeState = useAppSelector((state) => state.store);
  const allServices = useAppSelector(selectStoreServices, shallowEqual);

  const [searchValue, setSearchValue] = useState('');
  const [prevSearch, setPrevSearch] = useState<{ page: number, value: string }>({ page: 1, value: '' });
  const [timeZone, setTimezone] = useState('Europe/Berlin');
  const dispatch = useAppDispatch();
  const intl = useIntl();

  const navigate = useNavigate();

  const handleBack = useCallback(() => {
    navigate('/');
    dispatch(resetSearch());
  }, []);

  useEffect(() => {
    if (storeState.data.timezone && storeState.data.timezone !== timeZone) {
      setTimezone(storeState.data.timezone);
    }
  }, [storeState]);

  const handleCloseSearchBar = useCallback(() => {
    setSearchValue('');
  }, []);

  const debouncedSearchAppointments = useCallback((search: string, page: number) => {
    const today = TimeHelper.toDayjs(Date.now(), timeZone).startOf('day');
    const from = today;
    const query = {
      term: '',
      from,
      page,
      itemsPerPage: SEARCH_ITEMS_PER_PAGE,
      to: from.add(8, 'days'),
      storeId: storeState.data.id,
      enterpriseId: storeState.data.enterpriseId,
      timeZone,
    };
    if (search) {
      const fromPast = today.subtract(7, 'days');
      const to = today.add(365, 'days');
      const trimmed = search.trim();
      if (trimmed.length >= 3) {
        query.term = trimmed;
        query.from = fromPast;
        query.to = to;
      }
    }
    dispatch(searchCalendarEvents(query));
    setPrevSearch({ value: search, page });
  }, [storeState, timeZone]);

  const debouncedDispatch = useMemo(() => debounce(debouncedSearchAppointments, 500), [storeState]);

  useEffect(() => {
    if (!storeState.data.id || prevSearch.value === searchValue) {
      return;
    }
    debouncedDispatch(searchValue, 1);
  }, [searchValue, prevSearch, storeState]);

  useEffect(() => {
    if (
      !appointmentsState.loading &&
      storeState.data.id &&
      prevSearch.page === 1 &&
      appointmentsState.searchHasNextPage &&
      appointmentsState.searchData.length === 0
    ) {
      debouncedDispatch(searchValue, prevSearch.page);
    }
  }, [appointmentsState.loading, storeState, prevSearch]);

  const calendarEvents = useMemo(() => {
    if (appointmentsState.searchData.length < 1) {
      return [];
    }

    const sorted = appointmentsState.searchData.map((calendarEvent) => ({
      ...calendarEvent,
      dateTime: TimeHelper.toDayjs(calendarEvent.date, timeZone).startOf('day').add(calendarEvent.time, 'minutes').valueOf(),
    })).sort((a, b) => a.dateTime - b.dateTime);

    // using this to figure out first and last items in list
    let prevItemDayDay: number;

    const dayData: SearchCalendarEvent[] = [];
    sorted.forEach((calendarEvent) => {
      const day = TimeHelper.toDayjs(calendarEvent.date, timeZone).startOf('day');
      const dayStart = day.valueOf();
      let isFirstInDay = false;
      if (prevItemDayDay && prevItemDayDay === dayStart) {
        dayData[dayData.length - 1].isLastInDay = false;
        // reset last in day
      } else {
        // day is changed, set first in day
        prevItemDayDay = dayStart;
        isFirstInDay = true;
      }
      dayData.push({
        day,
        dateTime: calendarEvent.dateTime,
        isFirstInDay,
        isLastInDay: true,
        calendarEvent,
      });
    });

    return dayData;
  }, [appointmentsState.searchData, appointmentsState.searchFetched, prevSearch]);

  const hasNextPage = useMemo(() => !appointmentsState.loading && appointmentsState.searchHasNextPage, [appointmentsState]);

  const isFetchingNextPage = useMemo(
    () => {
      if (!storeState.data.id) {
        return false;
      }
      if (!appointmentsState.searchHasNextPage) {
        return false;
      }
      return appointmentsState.loading;
    },
    [appointmentsState, storeState],
  );

  const fetchNextPage = useCallback(() => {
    debouncedDispatch(searchValue, prevSearch.page + 1);
  }, [searchValue, prevSearch]);

  const renderRaw = useCallback(
    (data: SearchCalendarEvent) => (
      <SearchItem appointmentData={data} allServices={allServices} onClick={onClick} timeZone={timeZone} />
    ),
    [allServices, onClick, timeZone],
  );

  return (
    <Box className={styles.container}>
      <Box className={styles.header}>
        <Stack direction="row" justifyContent="space-between">
          <IconButton onClick={handleBack}>
            <BackIcon className={styles.backIcon} />
          </IconButton>
          <Stack direction="row" justifyContent="flex-start" alignItems="center" className={styles.titleContainer}>
            <Typography className={styles.title}>{intl.formatMessage({ id: 'search.title' })}</Typography>
          </Stack>
          <Stack direction="row" justifyContent="flex-start" alignItems="center" className={styles.searchContainer}>
            <SearchIcon height={16} width={24} className={styles.searchIcon} />
            <InputBase
              autoFocus
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder={`${intl.formatMessage({ id: 'common.search' })}...`}
              className={styles.input}
            />
            <Box className={styles.closeIcon}>
              <CloseIcon height={20} width={20} onClick={handleCloseSearchBar} />
            </Box>
          </Stack>
        </Stack>
      </Box>
      <Box className={styles.searchResultsContainer}>
        {calendarEvents.length > 0 ? (
          <VirtualList<SearchCalendarEvent>
            parentHeight="calc(100vh - 64px)"
            estimateSize={200}
            hideScroll
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
            fetchNextPage={fetchNextPage}
            data={calendarEvents}
            renderRaw={renderRaw}
          />
        ) : (
          <Typography className={styles.noResultsText}>
            {intl.formatMessage({ id: 'search.noResults' })}
          </Typography>
        )}
      </Box>
    </Box>
  );
}
